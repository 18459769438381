.site-main {
  .container();
  .clearfix();
  box-sizing: border-box;
  padding: 0 1em;

  // global content
  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }

  ul {
    // prevent overlapping on floats
    overflow: hidden;
  }

  blockquote {
    clear: both;
  }

  // image align classes
  .align-right {
    display: block;
    margin: 0 auto;

    @media (min-width: @screen-xs) {
      float: right;
      margin: 1/2em 0 1/2em 1/2em;
    }
  }

  .align-left {
    display: block;
    margin: 0 auto;

    @media (min-width: @screen-xs) {
      float: left;
      margin: 1/2em 1/2em 1/2em 0;
    }
  }

  .align-center {
    display: block;
    margin: 0 auto;
  }
  .fluid-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 35px;
  position: relative;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
}
