.site-navigation {
  @media (min-width: @menu-breakpoint) {
    flex: 1;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    
    li {
      display: inline-block;
      
      a {
        color: @body-fg;
        display: block;
        margin: .5em .5em;
        text-decoration: none;
        
        &:hover {
          color: @brand-primary;
        }
      }
      
      &.active {
        a {
          background-color: desaturate(lighten(@brand-secondary, 20%), 5%);
          border-radius: @border-radius-md;
          color: @white;
          padding: .25em .5em;
        }
      }
    }
  }
}

.child-navigation {
  background-color: desaturate(darken(@brand-secondary, 0%), 0%);
  box-sizing: border-box;
  color: @white;
  padding: 1em;
  width: 100%;
  
  @media (min-width: @screen-xs) {
    float: right;
    margin: 0 0 1em 1em;
    width: 15em;
  }
  
  h3 {
    border-bottom: 1px solid @white;
    font-weight: 300;
    margin: 0 0 1/4em 0;
    padding: 0 0 1/4em 0;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    font-size: 3/4em;
    line-height: 2em;
    position: relative;
    
    &.active {
      padding-left: 1em;
      
      &::before {
        background-color: lighten(@brand-secondary, 30%);
        content: "";
        display: block;
        height: 4px;
        left: 0;
        position: absolute;
        top: .8em;
        width: 4px;
      }
      
      a {
        color: lighten(@brand-secondary, 30%);
      }
    }
  }
  
  a {
    color: @white;
    text-decoration: none;
    
    &:hover {
      color: darken(@white, 40%);
    }
  }
}
