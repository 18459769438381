.site-header-wrapper {
  background-image: url('../images/header-border-top.svg');
  background-repeat: repeat-x;
  padding-top: 8px;
}

.site-header {
  .container();
  padding: 1em 0;
  text-align: center;
  
  @media (min-width: @menu-breakpoint) {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: flex-start;
  }
}

.site-branding {
}

.site-title {
  .hide-text();
  background-image: url('../images/branding.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  margin: 0 auto;
  height: 70px;
  width: 10em;
  
  @media (min-width: @menu-breakpoint) {
    margin: 0 1em 0 0;
  }
}

.site-main-action {
}
