.site-home-meta {
  .container();
  
  @media (min-width: @screen-sm) {
    display: flex;
  }
  
  h2 {
    border-bottom: 1px solid @white;
    font-weight: 300;
    margin: 0 0 1/4em 0;
    padding: 0 0 1/4em 0;
    text-align: center;
    
    @media (min-width: @screen-sm) {
      text-align: left;
    }
  }
}

.site-contact {
  background-color: @brand-secondary;
  box-sizing: border-box;
  color: @white;
  font-style: normal;
  padding: 1em 3/2em;
  text-align: center;
  
  @media (min-width: @screen-sm) {
    width: percentage(1/3);
  }
  
  a {
    color: @white;
  }
  
  .phone {
    display: block;
    font-size: 1.8em;
    font-weight: 700;
    margin: 1/4em 0;
    
    @media (min-width: @screen-md) {
      font-size: 2em;
    }
  }
  
  .directions {
    margin: 1em 0 1/4em;
  }
  
  .directions-subtext {
    display: block;
    font-size: .8em;
    font-weight: 300;
  }
}

.site-services {
  background-color: desaturate(lighten(@brand-secondary, 20%), 5%);
  box-sizing: border-box;
  color: @white;
  flex: 1;
  padding: 1em 1.5em 1.5em;
  text-align: center;
  
  h2 {
  }
  
  ul {
    .clearfix();
    list-style-type: none;
    margin: 0 0 1em 0;
    padding: 0;
    text-align: left;
  }
  
  li {
    background-image: url('../images/checkmark.svg');
    background-position: left center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    line-height: 2.5em;
    padding-left: 28px;
    
    @media (min-width: @screen-xs) {
      float: left;
      width: 50%;
    }
  }
}
