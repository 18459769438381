.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.container {
  margin: 0 auto;
  
  @media (min-width: @screen-md) {
    max-width: @screen-md;
  }
  
  @media (min-width: @screen-lg) {
    max-width: @screen-lg;
  }
  
  @media (min-width: @screen-xlg) {
    max-width: @screen-xlg;
  }
}

