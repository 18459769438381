.site-slider {
  .container();
  .clearfix();
  background-image: url('../images/slider-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: .25em solid desaturate(lighten(@brand-secondary, 20%), 5%);
  box-sizing: border-box;
  color: darken(@brand-secondary, 15%);
  display: flex;
  flex-direction: column;
  font-size: 1/2em;
  height: 150px;
  justify-content: center;
  overflow: hidden;
  padding: 1/2em;
  position: relative;
  z-index: 1;

  &::before {
    background-image: linear-gradient(90deg, @clear 20%, fade(@white, 95%));
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  @media (min-width: @screen-xs) {
    font-size: 3/4em;
    height: 220px;
  }

  @media (min-width: @screen-sm) {
    font-size: 1em;
    height: 350px;
  }

  @media (min-width: @screen-lg) {
    font-size: 3/2em;
    height: 500px;
  }

  .line-1,
  .line-2,
  .line-3 {
    position: relative;
    user-select: none;
    z-index: 100;

    @media (min-width: @screen-md) {
      margin: 3/2em 0;
    }
  }

  // Lines
  .line-1 {
    .animated();
    .fadeIn();
    margin-left: 53%;
  }

  .line-2 {
    .animated();
    .fadeIn();
    animation-delay: 3s;
    margin-left: 60%;
  }

  .line-3 {
    .animated();
    .fadeIn();
    animation-delay: 6s;
    margin-left: 55%;
  }

  // Span styles
  .xlarge {
    font-size: 2em;
  }

  .large {
    font-size: 1.5em;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .colored {
    color: @brand-primary;
  }
}
