body {
  background-color: @body-bg;
  font-family: @font-family-sans-serif;
}

a {
  color: @brand-primary;
  transition: color .4s;
  
  &:hover {
    color: darken(@brand-primary, 20%);
  }
}
