.site-footer {
  background-image: url('../images/header-border-top.svg');
  background-repeat: repeat-x;
  background-color: @footer-bg;
  box-sizing: border-box;
  
  a {
    color: darken(@footer-fg, 10%);
    
    &:hover {
      color: lighten(@footer-fg, 2%);
    }
  }
}

.site-info {
  .container();
  box-sizing: border-box;
  color: @footer-fg;
  padding: 1em;
  
  @media (min-width: @screen-sm) {
    display: flex;
  }
}

.footer-tagline,
.footer-hours,
.footer-contact {
  font-size: 3/4em;
  text-align: center;
  margin-bottom: 2em;
  
  @media (min-width: @screen-sm) {
    flex: 1;
    text-align: left;
    margin-bottom: 0;
  }
  
  h3 {
    margin: 0;
  }
  
  p {
    margin: 1/4em 0;
  }
  
  em {
    font-style: normal;
    font-weight: 700;
  }
}

.footer-hours {
  text-align: center;
}

.colophon {
  .container();
  box-sizing: border-box;
  color: @footer-fg;
  font-size: 3/4em;
  padding: 0 1em 1em;
  
  @media (min-width: @screen-sm) {
    display: flex;
  }
  
  p {
    line-height: 2em;
    margin: 0;
    
    @media (min-width: @screen-sm) {
      flex: 1;
    }
  }
  
  .copyright,
  .credit {
    text-align: center;
  }
  
  .copyright {
    @media (min-width: @screen-sm) {
      text-align: left;
    }
  }
  
  .credit {
    @media (min-width: @screen-sm) {
      text-align: right;
    }
  }
}
