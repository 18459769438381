.button {
  background-color: @brand-primary;
  border: none;
  border-radius: @border-radius-md;
  box-shadow: 0 2px 0 darken(@brand-primary, 15%);
  color: @white;
  display: inline-block;
  outline: none;
  padding: .5em 1em;
  text-decoration: none;
  transition: background-color .4s, box-shadow .4s;
  
  &:hover {
    background-color: darken(@brand-primary, 10%);
    color: @white;
  }
  
  &:focus,
  &:active {
    box-shadow: 0 2px 0 darken(@brand-primary, 10%);
  }
}

.button-secondary {
  background-color: lighten(@brand-secondary, 15%);
  box-shadow: 0 2px 0 darken(@brand-secondary, 15%);
  
  &:hover {
    background-color: lighten(@brand-secondary, 5%);
  }
  
  &:focus,
  &:active {
    box-shadow: 0 2px 0 darken(@brand-secondary, 10%);
  }
}

.button-super {
  font-weight: 300;
  padding: 1em 2em;
  text-transform: uppercase;
}

// button group (generated by grav form plugin)
.buttons {
  margin: 1em 0;
}
